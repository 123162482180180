<template>
  <div class="pwd-login">
    <van-field
      class="transpranent-input"
      placeholder="请输入手机号"
      type="digit"
      v-model="phoneNumber"
      maxlength="11"
      @blur="blurPhone"
    />
    <van-field
      class="transpranent-input"
      :type="showPassword?'text':'password'"
      placeholder="请输入密码"
      v-model="password"
      :right-icon="showPassword?require('@/assets/images/common_icon_play.png'):require('@/assets/images/common_icon_display.png')"
      @click-right-icon="clickRightIcon"
    />
    <van-popup
      v-model="visible"
      class="prompt-box"
      :closeable="true"
      @close="this.visible = false"
      :round="true"
      :close-on-click-overlay="false"
    >
      <h3>账号或密码有误请检查后操作</h3>
      <van-button
        class="go-reg"
        @click="visible = false"
      >知道了</van-button>
    </van-popup>
    <van-button class="login-register-btn" @click="login">登录</van-button>

    <div class="bottom-jump">
      <span @click="toPage('/register-login/register?type=reg')">账号注册</span>
      <span class="line"></span>
      <span @click="toPage('/register-login/codeLogin')">验证码登录</span>
      <span class="line"></span>
      <span @click="toPage('/register-login/register?type=forget')">忘记密码</span>
    </div>
  </div>
</template>

<script>
import { setPwdLogin } from "@/fetch/api/login";
import { Local } from '@/utils/local.js';
export default {
  data() {
    return {
      phoneNumber: null,
      visible: false,
      password: null,
      showPassword: false,
    };
  },
  components:{
  },
  methods: {
    clickRightIcon(){
      this.showPassword = !this.showPassword;
    },
    blurPhone(){
       if(!(/^1[3456789]\d{9}$/.test(this.phoneNumber)) && this.phoneNumber){ 
        this.$toast("手机号码有误，请重填");  
        return false; 
      }
    },
    toPage(path){
      this.$router.push(path)
    },
    
    login(){
      if(!this.phoneNumber){
        this.$toast('请填写手机号');
        return;
      }
       if(!(/^1[3456789]\d{9}$/.test(this.phoneNumber)) && this.phoneNumber){ 
        this.$toast("手机号码有误，请重填");  
        return; 
      }
      if(!this.password){
        this.$toast('请填写密码');
        return;
      }
      let params = {
        username: this.phoneNumber,
        password: this.password
      }
      setPwdLogin(params).then(res=>{
        if(res.code == 200){
          let token = res.result.token;
          let broker_id = res.result.broker_id;
          Local.set('token', token);
          Local.set('broker_id', broker_id)
          this.$router.replace("/home");
        }else{
          this.visible=true;
        }
      })
      // this.$router.replace('/home')
    }
  },
};
</script>

<style lang="scss" scoped>
.pwd-login {
  width: 84%;
  margin: 0 auto;
  padding-top: 4.45rem;
  overflow: hidden;
  .get-code {
    position: relative;
    .code-text {
      position: absolute;
      width: 2.6rem;
      right: 0.67rem;
      top: 0.32rem;
      height: 0.72rem;
      line-height: 0.72rem;
      text-align: center;
      font-size: 0.4rem;
      color: #ffffff;
      border-left: 0.03rem solid #fff;
      padding-left: 0.48rem;
      opacity: 0.8;
    }
  }
}
</style>
